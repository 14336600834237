<!-- 产品与服务4  Mes系统 -->
<template>
    <div class="product4">
        <banner-bar></banner-bar>

        <div class="boxes1" id="jumpPoint">
            <div class="pub_title_area">产品名称</div>
            <div class="name">MES系统</div>
            <img src="./../../assets/Product4/img1.png" alt="">
        </div>

        <div class="boxes2">
            <div class="pub_title_area">产品详情</div>
            <div class="title">产品简介</div>
            <img src="./../../assets/Product4/img2.png" alt="">
            <div v-html="content1" class="content" style="white-space:pre-wrap;">{{content1}}</div>
        </div>

        <div class="boxes3">
            <div class="title">功能总览</div>
            <img src="./../../assets/Product4/img3.png" alt="">
            <div class="title">全过程品质管理与质量追溯</div>
        </div>

        <div class="boxes4">
            <div class="area1">
                <div class="left">
                    <div class="pub_small_title">生产报工</div>
                    <div v-html="content2" class="pub_content" style="white-space:pre-wrap;">{{content2}}</div>
                </div>
                <img class="img4" src="./../../assets/Product4/img4.png" alt="">
            </div>
            <div class="area1">
                <img class="img5" src="./../../assets/Product4/img5.png" alt="">
                <div class="right">
                    <div v-html="content3" class="pub_content" style="white-space:pre-wrap;">{{content3}}</div>
                </div>
            </div>
            <div class="area2">
                <div class="pub_small_title">生产状态数据实时查询</div>
                <img class="img6" src="./../../assets/Product4/img6.png" alt="">
            </div>
            <div class="area2">
                <div class="pub_small_title">生产品质管理</div>
                <img class="img7" src="./../../assets/Product4/img7.png" alt="">
            </div>
            <div class="area1">
                <div class="left">
                    <div class="pub_small_title">E-SOP作业指导书</div>
                    <div v-html="content4" class="pub_content" style="white-space:pre-wrap;">{{content4}}</div>
                </div>
                <img class="img8" src="./../../assets/Product4/img8.png" alt="">
            </div>
            <div class="area2">
                <div class="pub_small_title">品质分析</div>
                <div v-html="content5" class="pub_content" style="white-space:pre-wrap;">{{content5}}</div>
                <img class="img9" src="./../../assets/Product4/img9.png" alt="">
            </div>
            <div class="area2">
                <div class="pub_small_title">仓库库位管理数字化</div>
                <img class="img10" src="./../../assets/Product4/img10.png" alt="">
            </div>
            <div class="area2">
                <div class="pub_small_title">设备管理</div>
                <div v-html="content6" class="pub_content content6" style="white-space:pre-wrap;">{{content6}}</div>
                <img class="img11" src="./../../assets/Product4/img11.png" alt="">
            </div>
            <div class="area2">
                <div class="pub_small_title">统计分析报表与看板</div>
                <img class="img12" src="./../../assets/Product4/img12.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import bannerBar from './../../components/bannerBar/bannerBar.vue'
export default{
    name:'Product4',
    components:{
        bannerBar
    },
    data(){
        return{
            content1:'&nbsp;MES系统是一套面向制造企业车间执行层的生产信息化管理系统。MES可以为企业提供包括制造数据管理、计划排程管理、生产调度管理、库存管理、质量管理、人力资源管理、工作中心/设备管理、工具工装管理、采购管理、成本管理、项目看板管理、生产过程控制、底层数据集成分析、上层数据集成分解等管理模块，为企业打造一个扎实、可靠、全面、可行的制造协同管理平台。',
            content2:'配料\n批次生产任务单下达：\n车间主任下达批次（原料批次）生产任务至首工序机台或投料口；（生产时标注生产类型：试产/批量）\n显示当月已完成量以及订单剩余量等信息。\n相关人员打印配料通知单（技术员）；\n现有为生产作业标准单格式；变更后的配料单样式（带二维码）；需要按照每缸进行下单；系统可按照标准量下达；',
            content3:'1.员工进入主界面，选择车间→进入操作界面；员工扫描批次配料单二维码，系统带出BOM物料清单；\n2.扫描投料口，带出生产机台；并和批次生产任务单进行比对是否批次机台投料正确；\n3.按物料顺序扫描物料条码，正确系统无提示，物料批次错误或顺序错误系统按类型提示报警；\n4.扫描物料完成后，员工将物料放置在称上。此时称的实时称重数据会在PDA界面中的实际中显示实时称重数据；点击确认，即可确认该笔称重数据。',
            content4:'独立现场客户端，操作人员能够在客户端上在线查看工位任务及作业指导书，并支持工位任务报工\n\n系统提供标准生产执行接口，可以自动接收下位系统的报工信息和工艺参数数据，并提供完整的展示界面，并支持手动录入功能，支持扫描录入物料信息，详细记录投料数量和原料批次\n\n移动APP工序管理界面，操作人员可以通过移动端进行参数录入，扫描投料，产出报工、操作指导书书查看等功能\n\n支持班组长管理产线的员工工位授权、工单报工、生产管理、工装报修、设备求援等',
            content5:'品质报表与分析：通过报表进一步分析品质原因排名，针对排名做解决方案，进一步降低次品不良浪费。',
            content6:'&emsp;&emsp;数字化工厂可对设备相关数据实时采集，通过与设备数据模型进行关联，实现动态监控和可视化展示，使设备的开机、停机、加工数量、利用率等信息更加透明，设备健康状态准确评估，设备参数与工艺参数便于追溯，异常情况通过移动端及时预警和分级报警，并对设备进行OEE分析、预防性维护、维修管理、备品备件管理等。',
        }
    },

    created(){
        this.$nextTick(()=>{
            this.tojumpPoint()
        })
    },

    methods:{
        tojumpPoint(){
            document.getElementById('jumpPoint').scrollIntoView({
                behavior:'smooth',
                block:'start'
            })
        }
    }
}
</script>
<style scoped lang="scss">
@import './Product4.scss'
</style>


