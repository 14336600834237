<!-- 产品与服务 banner -->
<template>
    <div class="banner">
        <div class="banner_title">
            <p class="p1">产&nbsp;&nbsp;品&nbsp;&nbsp;中&nbsp;&nbsp;心</p>
            <p class="p2">超 越 软 件 智 造 未 来</p>
        </div>
    </div>
</template>
<script>
export default{
    name:'bannerBar'
}
</script>
<style scoped lang="scss">
.banner{
    width:19.2rem;
    height:8.6rem;
    background-image: url(./../../assets/productService/banner.png);
    background-size:cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items:flex-end;
    .banner_title{
        width:12rem;
        height:6rem;
        position: relative;
        .p1{
            font-size: 0.5rem;
            font-weight: bold;
            color:#fff;
            position: absolute;
            top:80px;
        }
        .p2{
            font-size: 0.3rem;
            font-weight: 500;
            color:#fff;
            position: absolute;
            top:180px;
        }
    }
}

@media screen and (max-width:770px){
    .banner{
        width:19.2rem;
        height:8.6rem;
        background-image: url(./../../assets/productService/banner.png);
        background-size:cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items:flex-end;
        .banner_title{
            width:14.6rem;
            height:5rem;
            position: relative;
            .p1{
                font-size: 0.7rem;
                font-weight: bold;
                color:#fff;
                position: absolute;
                top:0px;
            }
            .p2{
                font-size: 0.45rem;
                font-weight: 500;
                color:#fff;
                position: absolute;
                top:32px;
            }
        }
    }
}
</style>